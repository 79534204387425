import React from "react"
import Topic from "../../components/Topic"

const Community = () => (
  <Topic id="community">
    <p>
      Canadians are known for their diversity and generosity. Many services are
      offered by communities across Canada including free programs offered at
      public libraries, activities at community centres, free play areas for
      children and their caregivers, free food from food banks for those in
      need, and community support from various churches. Included here is
      information to help you find services in your community, as well as ways
      of connecting with the people in your community who can be part of your
      support network.
    </p>
  </Topic>
)

export default Community
